<template>
  <div class="animated fadeIn">
    <b-card style="flex-direction: unset; border: none">
      <div class="card-body">
        <b-tabs content-class="mt-3" justified>
          <b-tab title="Overview" active>
            <form>
              <h5>
                Account details
                <b-spinner v-if="isLoading" small type="grow" />
              </h5>
              <hr />
              <b-row>
                <b-col lg="3" md="3">
                  <inline-input
                    ref="account-name"
                    :id="controls.account_name.id"
                    :value="data.account_name"
                    :label="controls.account_name.label"
                    :readonly="false"
                    :mode="mode"
                    :required="true"
                    @keyup="onAccountNameKeyUp"
                    @changed="updateField"
                  />
                </b-col>
                <b-col lg="3" md="3">
                  <inline-input
                    :id="controls.website.id"
                    :value="data.website"
                    :label="controls.website.label"
                    :readonly="false"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
                <b-col lg="3" md="3">
                  <inline-input
                    :id="controls.phone.id"
                    :value="data.phone"
                    :label="controls.phone.label"
                    :readonly="false"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
                <b-col lg="3" md="3">
                  <inline-input
                    :id="controls.fax.id"
                    :value="data.fax"
                    :label="controls.fax.label"
                    :readonly="false"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3" md="3">
                  <inline-input
                    :id="controls.email.id"
                    :value="data.email"
                    :label="controls.email.label"
                    :readonly="false"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
                <!--
                    <b-col lg="3" md="3">
                        <inline-input :id="controls.email_optout.id" :value="data.email_optout" :label="controls.email_optout.label" :readonly="false" :mode="mode" :required="false" />
                    </b-col>
                    <b-col lg="3" md="3">
                        <inline-select :id="controls.type.id" :value="data.type" :label="controls.type.label" :readonly="controls.type.readonly" :options="controls.type.options" :allowEmpty="false" :mode="mode" @changed="updateSelect" :required="true" />
                    </b-col>
                    -->
                <b-col lg="3" md="3">
                  <inline-select
                    :id="controls.category.id"
                    :value="data.category"
                    :label="controls.category.label"
                    :readonly="controls.category.readonly"
                    :options="controls.category.options"
                    :allow-empty="false"
                    :mode="mode"
                    @changed="updateSelect"
                    :required="true"
                  />
                </b-col>
                <b-col lg="3" md="3">
                  <inline-switch
                    v-if="$permitted(controls.visible_global.id).visible"
                    :id="controls.visible_global.id"
                    :value="data.visible_global"
                    :readonly="false"
                    :label="controls.visible_global.label"
                    @changed="onVisibleGlobalChange"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3" md="3">
                  <inline-input
                    :id="controls.created.id"
                    :value="data.created"
                    :label="controls.created.label"
                    :readonly="controls.created.readonly"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
                <b-col lg="3" md="3">
                  <inline-input
                    :id="controls.modified.id"
                    :value="data.modified"
                    :label="controls.modified.label"
                    :readonly="controls.modified.readonly"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>

                <b-col lg="3" md="3" v-if="data.last_activity_date !== ''">
                  <inline-input
                    id="last_activity_date"
                    :value="data.last_activity_date"
                    label="Last activity date"
                    :readonly="true"
                    :mode="$constants.FORMCONTROLMODE.VIEW"
                  />
                </b-col>

                <b-col lg="3" md="3" v-if="data.last_activity_date !== ''">
                  <inline-select
                    id="last_activity"
                    :value="{
                      label: data.last_activity_subject,
                      id: data.last_activity_id
                    }"
                    label="Last activity"
                    :readonly="true"
                    :mode="$constants.FORMCONTROLMODE.VIEW"
                    :link-mode="true"
                    @link-clicked="onLastActivityLinkClicked"
                  />
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col cols="3">
                  <font-awesome-icon icon="chevron-down" />
                  <h5
                    v-b-toggle.collapse-1
                    style="display: inline; cursor: pointer"
                  >
                    Billing address details
                  </h5>
                </b-col>
              </b-row>

              <b-collapse id="collapse-1" class="mt-2">
                <b-row>
                  <b-col lg="3" md="3">
                    <inline-input
                      :id="controls.street.id"
                      :value="data.street"
                      :label="controls.street.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                  <b-col lg="3" md="3">
                    <inline-select
                      :id="controls.country.id"
                      :value="data.country"
                      :label="controls.country.label"
                      :readonly="controls.country.readonly"
                      :options="controls.country.options"
                      :allow-empty="false"
                      :mode="mode"
                      @changed="updateSelect"
                      :required="true"
                    />
                  </b-col>

                  <b-col lg="3" md="3">
                    <inline-select
                      :id="controls.state.id"
                      :value="data.state"
                      :label="controls.state.label"
                      :readonly="controls.state.readonly"
                      :options="controls.state.options"
                      :allow-empty="false"
                      :taggable="isStateTaggable"
                      :mode="mode"
                      @changed="updateSelect"
                      :required="true"
                    />
                  </b-col>
                  <b-col lg="3" md="3">
                    <inline-select
                      :id="controls.city.id"
                      :value="data.city"
                      :label="controls.city.label"
                      :readonly="controls.city.readonly"
                      :options="controls.city.options"
                      :allow-empty="false"
                      :taggable="true"
                      :mode="mode"
                      @changed="updateSelect"
                      :required="true"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="3" md="3">
                    <inline-input
                      :id="controls.postal_code.id"
                      :value="data.postal_code"
                      :label="controls.postal_code.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                  <b-col lg="3" md="3">
                    <inline-input
                      :id="controls.pobox.id"
                      :value="data.pobox"
                      :label="controls.pobox.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                  <b-col lg="3" md="3">
                    <inline-input
                      :id="controls.latitude.id"
                      :value="data.latitude"
                      :label="controls.latitude.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                  <b-col lg="3" md="3">
                    <inline-input
                      :id="controls.longitude.id"
                      :value="data.longitude"
                      :label="controls.longitude.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                </b-row>
              </b-collapse>
              <hr />
              <b-row>
                <b-col cols="3">
                  <font-awesome-icon icon="chevron-down" />
                  <h5
                    v-b-toggle.collapse-2
                    style="display: inline; cursor: pointer"
                  >
                    Distributor information
                  </h5>
                </b-col>
              </b-row>

              <b-collapse id="collapse-2" class="mt-2">
                <b-row>
                  <b-col lg="3" md="3">
                    {{ controls.current_dist_contacts.label }}
                    <b-input-group>
                      <b-input-group-append>
                        <b-button
                          variant="outline-dark"
                          @click="copyDistributorInfo"
                        >
                          <font-awesome-icon icon="copy" />
                        </b-button>
                      </b-input-group-append>
                      <inline-select
                        :id="controls.current_dist_contacts.id"
                        :value="data.current_dist_contacts"
                        :readonly="controls.current_dist_contacts.readonly"
                        :options="controls.current_dist_contacts.options"
                        :allow-empty="true"
                        :multiple="true"
                        :mode="mode"
                        @changed="updateSelect"
                        :required="false"
                        style="width:87%"
                      />
                    </b-input-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="3" md="3">
                    <inline-input
                      :id="controls.ship_email.id"
                      :value="data.ship_email"
                      :label="controls.ship_email.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                  <b-col lg="3" md="3">
                    <inline-input
                      :id="controls.ship_contact.id"
                      :value="data.ship_contact"
                      :label="controls.ship_contact.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                  <b-col lg="3" md="3">
                    <inline-input
                      :id="controls.bill_email.id"
                      :value="data.bill_email"
                      :label="controls.bill_email.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                  <b-col lg="3" md="3">
                    <inline-input
                      :id="controls.bill_contact.id"
                      :value="data.bill_contact"
                      :label="controls.bill_contact.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="3" md="3">
                    <inline-input
                      :id="controls.tax_wh_number.id"
                      :value="data.tax_wh_number"
                      :label="controls.tax_wh_number.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                  <b-col lg="3" md="3">
                    <inline-input
                      :id="controls.fein.id"
                      :value="data.fein"
                      :label="controls.fein.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                  <b-col lg="3" md="3">
                    <inline-input
                      :id="controls.tax_wh_name.id"
                      :value="data.tax_wh_name"
                      :label="controls.tax_wh_name.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                  <b-col lg="3" md="3">
                    <inline-input
                      :id="controls.auth_wh_keeper_number.id"
                      :value="data.auth_wh_keeper_number"
                      :label="controls.auth_wh_keeper_number.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="3" md="3">
                    <inline-input
                      :id="controls.ship_street.id"
                      :value="data.ship_street"
                      :label="controls.ship_street.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                  <b-col lg="3" md="3">
                    <inline-select
                      :id="controls.ship_country.id"
                      :value="data.ship_country"
                      :label="controls.ship_country.label"
                      :readonly="controls.ship_country.readonly"
                      :options="controls.ship_country.options"
                      :allow-empty="false"
                      :mode="mode"
                      @changed="updateSelect"
                      :required="false"
                    />
                  </b-col>

                  <b-col lg="3" md="3">
                    <inline-select
                      :id="controls.ship_state.id"
                      :value="data.ship_state"
                      :label="controls.ship_state.label"
                      :readonly="controls.ship_state.readonly"
                      :options="controls.ship_state.options"
                      :allow-empty="false"
                      :taggable="true"
                      :mode="mode"
                      @changed="updateSelect"
                      :required="false"
                    />
                  </b-col>
                  <b-col lg="3" md="3">
                    <inline-select
                      :id="controls.ship_city.id"
                      :value="data.ship_city"
                      :label="controls.ship_city.label"
                      :readonly="controls.ship_city.readonly"
                      :options="controls.ship_city.options"
                      :allow-empty="false"
                      :taggable="true"
                      :mode="mode"
                      @changed="updateSelect"
                      :required="false"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="3" md="3">
                    <inline-input
                      :id="controls.ship_postal_code.id"
                      :value="data.ship_postal_code"
                      :label="controls.ship_postal_code.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                  <b-col lg="3" md="3">
                    <inline-input
                      :id="controls.ship_pobox.id"
                      :value="data.ship_pobox"
                      :label="controls.ship_pobox.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      @changed="updateField"
                    />
                  </b-col>
                </b-row>
              </b-collapse>
              <hr />

              <b-row>
                <b-col cols="2">
                  <font-awesome-icon icon="chevron-down" />
                  <h5
                    v-b-toggle.collapse-3
                    style="display: inline; cursor: pointer"
                  >
                    Description
                  </h5>
                </b-col>
              </b-row>
              <b-collapse id="collapse-3" class="mt-2">
                <b-row>
                  <b-col lg="12" md="12">
                    <inline-input
                      :id="controls.description.id"
                      :value="data.description"
                      :label="controls.description.label"
                      :readonly="false"
                      :mode="mode"
                      :required="false"
                      :is-text-area="true"
                      @changed="updateField"
                    />
                  </b-col>
                </b-row>
              </b-collapse>
            </form>
          </b-tab>

          <b-tab
            :title="controls.tabs.contacts.title"
            v-if="mode !== $constants.FORM_MODE.CREATE"
          >
            <contacts-table :account-id="id" @loaded="onContactsLoad" />
          </b-tab>
          <b-tab
            :title="controls.tabs.activities.title"
            v-if="mode !== $constants.FORM_MODE.CREATE"
          >
            <activities-table :account-id="id" @loaded="onActivitiesLoad" />
          </b-tab>
          <b-tab
            :title="controls.tabs.invoices.title"
            v-if="mode !== $constants.FORM_MODE.CREATE"
          >
            <invoices-table :account-id="id" @loaded="onInvoicesLoad" />
          </b-tab>
          <b-tab
            :title="controls.tabs.orders.title"
            v-if="
              mode !== $constants.FORM_MODE.CREATE &&
                data.category.label === 'Supplier'
            "
          >
            <orders-table :account-id="id" @loaded="onOrdersLoad" />
          </b-tab>

          <b-tab
            :title="controls.tabs.files.title"
            v-if="mode !== $constants.FORM_MODE.CREATE"
          >
            <files-container
              :module-id="$constants.MODULES.Accounts.id"
              :entity-id="id"
              :show-category="true"
              @loaded="onFileContainerLoad"
            />
          </b-tab>
          <b-tab
            :title="controls.tabs.dataHistory.title"
            v-if="mode !== $constants.FORM_MODE.CREATE"
          >
            <data-history-table
              module="accounts"
              :entity-id="id"
              @loaded="onDataHistoryLoad"
            />
          </b-tab>
          <b-tab
            :title="controls.tabs.warehouseOrders.title"
            v-if="mode !== $constants.FORM_MODE.CREATE && isDistributor"
          >
            <warehouse-orders-table
              :account-id="id"
              @loaded="onWarehouseOrdersLoad"
            />
          </b-tab>
          <b-tab
            :title="controls.tabs.warehousePicklists.title"
            v-if="mode !== $constants.FORM_MODE.CREATE && isDistributor"
          >
            <warehouse-picklists-table
              :account-id="id"
              @loaded="onWarehousePicklistsLoad"
            />
          </b-tab>
          <b-tab
            :title="controls.tabs.warehouseInvoices.title"
            v-if="mode !== $constants.FORM_MODE.CREATE && isDistributor"
          >
            <warehouse-invoices-table
              :account-id="id"
              @loaded="onWarehouseInvoicesLoad"
            />
          </b-tab>

          <b-tab
            :title="controls.tabs.markets.title"
            v-if="mode !== $constants.FORM_MODE.CREATE && isDistributor"
          >
            <markets-table :account-id="id" @loaded="onMarketsLoad" />
          </b-tab>
        </b-tabs>
        <form-submission-actions
          v-if="action !== 'preview'"
          :mode="mode"
          :loading="{
            save: saveInProgress,
            saveAndView: saveAndViewInProgress
          }"
          :buttons-visibility="{
            previous: pagination.prev,
            next: pagination.next
          }"
          @previous-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: pagination.prev.id,
                page: pagination.prev.page
              }
            })
          "
          @next-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: pagination.next.id,
                page: pagination.next.page
              }
            })
          "
          @save="
            save('tabular').then(response =>
              response ? $router.push({ name: 'Accounts' }) : false
            )
          "
          @save-and-view="
            save('view').then(response =>
              response
                ? $router.push({
                    name: 'Account submission',
                    params: { action: 'view', id: response }
                  })
                : false
            )
          "
          @edit="
            $router.push({
              name: 'Account submission',
              params: { action: 'edit', id: id }
            })
          "
          @back="$router.push($store.getters['router/previousRoute'])"
        />
      </div>
    </b-card>

    <b-modal
      ref="activity-preview-modal"
      centered
      scrollable
      size="lg"
      :title="activityPreviewModal.title"
    >
      <b-container fluid class="ma-0 pa-0" style="height: 80vh">
        <ActivitySubmissionForm
          :id="activityPreviewModal.id"
          action="preview"
        />
      </b-container>
      <template #modal-footer="" />

      <hr />
      <div class="form-row d-flex justify-content-end">
        <b-button
          variant="outline-dark"
          class="m-1"
          @click="openActivityInNewWindow(activityPreviewModal.id)"
        >
          <font-awesome-icon icon="share" /> Open in new window
        </b-button>

        <b-button
          variant="outline-dark"
          class="m-1"
          @click="closeActivityPreview()"
        >
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </b-modal>
    <b-modal
      ref="vm-modal-form"
      class="vm-modal"
      centered
      hide-footer
      size="sm"
      :title="marketModal.title"
    >
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <b-form-input
              id="market-weight"
              type="number"
              v-model="marketModal.value.weight"
            />
          </b-col>
        </b-row>
      </b-container>

      <hr />
      <div class="form-row d-flex justify-content-end">
        <b-button
          variant="outline-dark"
          class="m-1"
          @click="saveMarketsModal()"
        >
          <font-awesome-icon icon="save" />&nbsp;Save
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import InlineInput from '@/components/InlineInput'
import InlineSelect from '@/components/InlineSelectMultiple'
import InlineSwitch from '@/components/InlineSwitch'

import FilesContainer from '@/components/FilesContainer'
import ContactsTable from '@/views/RelationshipManagement/ContactsTable'
import ActivitiesTable from '@/views/AccountTouchpoints/ActivitiesTable'
import InvoicesTable from '@/views/SalesAdministrative/InvoicesTable'
import OrdersTable from '@/views/Inventory/OrdersTable'

import InlineEditor from '@ckeditor/ckeditor5-build-inline'

import FormSubmissionActions from '@/components/FormSubmissionActions'
import ActivitySubmissionForm from '@/views/AccountTouchpoints/ActivitySubmissionForm'

import DataHistoryTable from '@/components/DataHistoryTable'
import WarehouseOrdersTable from '@/views/Warehouse/IncomingOrders/IncomingOrdersTable'
import WarehousePicklistsTable from '@/views/Warehouse/PickLists/PickListsTable'
import WarehouseInvoicesTable from '@/views/Warehouse/Invoices/InvoicesTable'
import MarketsTable from '@/views/Warehouse/MarketsTable'

//import controls from './controls'
//import data from './data'

import sharedEvents from '@/shared/sharedEvents.js'

export default {
  name: 'AccountSubmissionForm',
  props: {
    id: {
      type: [String, Number],
      default: ''
    },
    action: {
      type: String,
      default: ''
    },
    page: {
      type: String,
      default: ''
    }
  },
  components: {
    InlineInput,
    InlineSelect,
    FilesContainer,
    ContactsTable,
    ActivitiesTable,
    InvoicesTable,
    FormSubmissionActions,
    ActivitySubmissionForm,
    DataHistoryTable,
    InlineSwitch,
    OrdersTable,
    WarehouseOrdersTable,
    WarehousePicklistsTable,
    WarehouseInvoicesTable,
    MarketsTable
  },
  data: function () {
    return {
      editor: InlineEditor,
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      isStateTaggable: false,
      mode: undefined,
      REPORTS_FREQUENCY: {
        DAILY: '1'
      },
      activityPreviewModal: {
        id: '',
        title: ''
      },
      marketModal: {
        title: 'Market weight (%)',

        value: {
          id: 0,
          weight: 0
        }
      },
      controls: {
        tabs: {
          files: {
            title: 'Files'
          },
          orders: {
            title: 'Purchases'
          },
          contacts: {
            title: 'Contacts'
          },
          activities: {
            title: 'Activities'
          },
          invoices: {
            title: 'Invoices'
          },
          dataHistory: {
            title: 'Data History'
          },
          warehouseOrders: {
            title: 'WH Orders'
          },
          warehousePicklists: {
            title: 'WH Picklists'
          },
          warehouseInvoices: {
            title: 'WH Invoices'
          },
          markets: {
            title: 'Markets'
          }
        },
        buttons: {
          edit: {
            id: 'button:details_edit'
          },
          save: {
            id: 'button:details_save'
          }
        },
        account_name: {
          id: 'input:account_name',
          label: 'Account Name',
          changed: false,
          readonly: false
        },
        website: {
          id: 'input:website',
          label: 'Website',
          changed: false,
          readonly: false
        },
        phone: {
          id: 'input:phone',
          label: 'Phone',
          changed: false,
          readonly: false
        },
        fax: {
          id: 'input:fax',
          label: 'Fax',
          changed: false,
          readonly: false
        },
        email: {
          id: 'input:email',
          label: 'Email',
          changed: false,
          readonly: false
        },
        email_optout: {
          id: 'input:email_optout',
          label: 'Email Opt Out',
          changed: false,
          readonly: false
        },
        type: {
          id: 'select:type',
          label: 'Type',
          options: [],
          changed: false,
          readonly: false
        },
        category: {
          id: 'select:category',
          label: 'Category',
          options: [],
          changed: false,
          readonly: false
        },
        street: {
          id: 'input:street',
          label: 'Billing Address',
          changed: false,
          readonly: false
        },
        country: {
          id: 'select:country',
          label: 'Billing Country',
          options: [],
          changed: false,
          readonly: false
        },
        state: {
          id: 'select:state',
          label: 'Billing State',
          options: [],
          changed: false,
          readonly: false
        },
        city: {
          id: 'select:city',
          label: 'Billing City',
          options: [],
          changed: false,
          readonly: false
        },
        postal_code: {
          id: 'input:postal_code',
          label: 'Billing Postal Code',
          changed: false,
          readonly: false
        },
        pobox: {
          id: 'input:pobox',
          label: 'Billing PO Box',
          changed: false,
          readonly: false
        },
        latitude: {
          id: 'input:latitude',
          label: 'Latitude',
          changed: false,
          readonly: false
        },
        longitude: {
          id: 'input:longitude',
          label: 'Longitude',
          changed: false,
          readonly: false
        },
        current_dist_contacts: {
          id: 'select:current_dist_contacts',
          label: 'Current Contacts',
          changed: false,
          readonly: false
        },
        ship_street: {
          id: 'input:ship_street',
          label: 'Shipping Address',
          changed: false,
          readonly: false
        },
        ship_country: {
          id: 'select:ship_country',
          label: 'Shipping Country',
          options: [],
          changed: false,
          readonly: false
        },
        ship_state: {
          id: 'select:ship_state',
          label: 'Shipping State',
          options: [],
          changed: false,
          readonly: false
        },
        ship_city: {
          id: 'select:ship_city',
          label: 'Shipping City',
          options: [],
          changed: false,
          readonly: false
        },
        ship_postal_code: {
          id: 'input:ship_postal_code',
          label: 'Shipping Postal Code',
          changed: false,
          readonly: false
        },
        ship_pobox: {
          id: 'input:ship_pobox',
          label: 'Shipping PO Box',
          changed: false,
          readonly: false
        },
        ship_email: {
          id: 'input:ship_email',
          label: 'Shipping Email',
          changed: false,
          readonly: false
        },
        ship_contact: {
          id: 'input:ship_contact',
          label: 'Shipping Contact',
          changed: false,
          readonly: false
        },
        bill_email: {
          id: 'input:bill_email',
          label: 'Billing Email',
          changed: false,
          readonly: false
        },
        bill_contact: {
          id: 'input:bill_contact',
          label: 'Billing Contact',
          changed: false,
          readonly: false
        },
        tax_wh_number: {
          id: 'input:tax_wh_number',
          label: 'Tax Warehouse Number',
          changed: false,
          readonly: false
        },
        fein: {
          id: 'input:fein',
          label: 'FEIN',
          changed: false,
          readonly: false
        },
        tax_wh_name: {
          id: 'input:tax_wh_name',
          label: 'Tax Warehouse Name',
          changed: false,
          readonly: false
        },
        auth_wh_keeper_number: {
          id: 'input:auth_wh_keeper_number',
          label: 'Authorized WH Keeper Number',
          changed: false,
          readonly: false
        },

        description: {
          id: 'input:description',
          label: 'Description',
          changed: false,
          readonly: false
        },
        created: {
          id: 'input:created',
          label: 'Created',
          changed: false,
          readonly: true
        },
        modified: {
          id: 'input:modified',
          label: 'Modified',
          changed: false,
          readonly: true
        },

        markets: {
          id: 'select:markets',
          label: 'Markets weights',
          options: [],
          changed: false,
          readonly: false
        },
        visible_global: {
          id: 'switch:visible_global',
          label: 'Visible global',
          value: false,
          changed: false,
          readonly: false
        }
      },
      data: {
        id: '',
        account_name: '',
        website: '',
        phone: '',
        fax: '',
        memberof: '',
        email_optout: '',
        type: {
          id: '',
          label: ''
        },
        category: {
          id: '',
          label: ''
        },
        street: '',
        country: {
          id: '',
          label: ''
        },
        state: {
          id: '',
          label: ''
        },
        city: {
          id: '',
          label: ''
        },
        postal_code: '',
        pobox: '',
        latitude: '',
        longitude: '',
        current_dist_contacts: [],
        ship_street: '',
        ship_country: {
          id: '',
          label: ''
        },
        ship_state: {
          id: '',
          label: ''
        },
        ship_city: {
          id: '',
          label: ''
        },
        ship_postal_code: '',
        ship_pobox: '',
        ship_email: '',
        ship_contact: '',
        bill_email: '',
        bill_contact: '',

        tax_wh_number: '',
        fein: '',
        tax_wh_name: '',
        auth_wh_keeper_number: '',
        description: '',
        created: '',
        modified: '',
        last_activity_id: '',
        last_activity_date: '',
        last_activity_subject: '',
        markets: [],
        visible_global: false
      },
      districts: [],
      pagination: {
        prev: false,
        next: false
      }
    }
  },
  computed: {
    isDistributor () {
      return (
        this.data.category &&
        ['Distributor', 'Old Distributor'].includes(this.data.category.label)
      )
    }
  },
  created () {
    this.initialize()
  },
  mounted: async function () {},
  methods: {
    copyDistributorInfo () {
      const emails = this.data.current_dist_contacts.map(
        contact => contact.email
      )

      console.log('emails', emails)
      let _emails = emails.join(', ')

      navigator.clipboard
        .writeText(_emails)
        .then(() => {
          this.$form.makeToastInfo('Distributor emails copied to clipboard')
        })
        .catch(err => {
          this.$form.makeToastError('Distributor emails copy failed')
        })
    },
    onLastActivityLinkClicked (e) {
      this.activityPreviewModal.id = e.id
      this.activityPreviewModal.title = `Activity #${e.id}`

      this.$refs['activity-preview-modal'].show()
    },
    openActivityPreview () {
      this.$refs['activity-preview-modal'].show()
    },
    closeActivityPreview: function () {
      this.$refs['activity-preview-modal'].hide()
    },
    openActivityInNewWindow (id) {
      let routeData = this.$router.resolve({
        name: 'Activity submission',
        params: {
          id: id,
          action: 'view'
        }
      })
      window.open(routeData.href, '_blank')
    },
    onDataHistoryLoad (count) {
      if (count > 0)
        this.controls.tabs.dataHistory.title = `Data History (${count})`
    },
    onMarketSelected (e) {
      this.marketModal.value = e

      this.data.markets.push(e)

      this.$refs['vm-modal-form'].show()
    },
    onMarketRemoved (e) {
      let self = this
      let o = self.controls.markets.options.find(o => o.id === e.id)
      o.weight = 100
      o.label = `${o.label_orig}`

      this.data.markets = this.data.markets.filter(m => m.id !== e.id)

      this.updateField('markets', this.data.markets, this.mode)
    },
    onMarketChanged () {
      //    this.data.markets = value
    },
    saveMarketsModal () {
      let self = this
      let o = self.controls.markets.options.find(
        o => o.id === self.marketModal.value.id
      )
      o.weight = parseFloat(self.marketModal.value.weight)
      o.label = `${o.label_orig} (${o.weight.toFixed(2)}%)`

      this.$refs['vm-modal-form'].hide()

      this.updateField('markets', this.data.markets, this.mode)
    },
    closeMarketsModal () {
      // this.modalForm.mode = undefined;
      this.$refs['vm-modal-form'].hide()
    },
    onAccountNameKeyUp: async function (e) {
      //let _value = e.srcElement._value
      //let _value = e.currentTarget.value
      let _value = e.val

      if (_value.length > 1) {
        let isUnique = await this.$api.post('accounts/checkuniq', {
          value: _value
        })

        if (!isUnique) {
          this.$refs['account-name'].setCustomError('Account already exists')
        } else {
          this.$refs['account-name'].setCustomError('')
        }
      }

      if (!_value.length) this.$refs['account-name'].setCustomError('')
    },
    onFileContainerLoad (count) {
      if (count > 0) this.controls.tabs.files.title = `Files (${count})`
    },
    onContactsLoad (count) {
      if (count > 0) this.controls.tabs.contacts.title = `Contacts (${count})`
    },
    onActivitiesLoad (count) {
      if (count > 0)
        this.controls.tabs.activities.title = `Activities (${count})`
    },
    onInvoicesLoad (count) {
      if (count > 0) this.controls.tabs.invoices.title = `Invoices (${count})`
    },
    onOrdersLoad (count) {
      if (count > 0) this.controls.tabs.orders.title = `Purchases (${count})`
    },
    onWarehouseOrdersLoad (count) {
      if (count > 0)
        this.controls.tabs.warehouseOrders.title = `WH Orders (${count})`
    },
    onWarehousePicklistsLoad (count) {
      if (count > 0)
        this.controls.tabs.warehousePicklists.title = `WH Picklists (${count})`
    },
    onWarehouseInvoicesLoad (count) {
      if (count > 0)
        this.controls.tabs.warehouseInvoices.title = `WH Invoices (${count})`
    },
    onMarketsLoad (count) {
      if (count > 0) this.controls.tabs.markets.title = `Markets (${count})`
    },

    initialize: async function () {
      let self = this

      if (this.action === 'create') {
        this.mode = this.$constants.FORM_MODE.CREATE
      }
      if (this.action === 'edit') {
        this.mode = this.$constants.FORM_MODE.EDIT
      }
      if (this.action === 'view') {
        this.mode = this.$constants.FORM_MODE.VIEW
      }

      this.data.id = this.id

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        //make request and save to session to avoid several requests to server from buttons
        this.$customTable
          .getPrevNextServerKeys({
            module: this.$route.meta.module,
            id: this.id,
            page: this.page
          })
          .then(response => (self.pagination = response))

        await this.fetchAccount(this.id)
      }

      this.$api
        .post('dictionaries/countries/mapped', {
          ignore_restrictions: true
        })
        .then(response => {
          self.districts = response

          self.controls.country.options = self.$helpers.getDistinctArray(
            response,
            'country',
            'country',
            'id',
            'label'
          )
          self.controls.state.options = self.$helpers.getDistinctArray(
            response.filter(s => s.country === self.data.country.id),
            'state',
            'state',
            'id',
            'label'
          )
          self.controls.city.options = self.$helpers.getDistinctArray(
            response.filter(s => s.state === self.data.state.id),
            'city',
            'city',
            'id',
            'label'
          )

          self.controls.ship_country.options = self.$helpers.getDistinctArray(
            response,
            'country',
            'country',
            'id',
            'label'
          )
          self.controls.ship_state.options = self.$helpers.getDistinctArray(
            response.filter(s => s.country === self.data.country.id),
            'state',
            'state',
            'id',
            'label'
          )
          self.controls.ship_city.options = self.$helpers.getDistinctArray(
            response.filter(s => s.state === self.data.state.id),
            'city',
            'city',
            'id',
            'label'
          )
        })

      this.$api.get(`accounts/${this.id}/contacts`).then(response => {
        self.controls.current_dist_contacts.options = response.data.map(
          item => {
            return {
              id: item.ID,
              label: item['Contact Name'],
              email: item['Email']
            }
          }
        )

        self.$forceUpdate()

        console.log(
          'this.controls.current_dist_contacts.options:',
          self.controls.current_dist_contacts.options
        )
      })

      this.$api.get('dictionaries/accounts/categories').then(response => {
        this.controls.category.options = response.map(item => {
          return {
            id: item.id,
            label: item.name
          }
        })
      })
      this.$api.get('accounts/types').then(response => {
        this.controls.type.options = response.map(item => {
          return {
            id: item.id,
            label: item.name
          }
        })
      })

      //let self = this

      this.$api.get('dictionaries/markets').then(response => {
        self.controls.markets.options = response.map(u => ({
          id: u.id,
          label: u.name,
          label_orig: u.name,
          weight: 100
        }))

        self.data.markets = []
      })
    },
    edit () {},

    save (_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError('Form contains errors')
        return Promise.resolve(false)
      }

      let self = this
      let method = ''

      let url = 'accounts'
      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        method = 'put'
        url = '/accounts'
      }
      if (this.mode === this.$constants.FORM_MODE.EDIT) {
        method = 'put'
        url = `/accounts/${this.data.id}`
      }

      self.isLoading = true
      this.saveInProgress = _mode === 'tabular'
      this.saveAndViewInProgress = _mode === 'view'

      return this.$api[method](url, this.data)
        .then(response => {
          self.isLoading = false
          self.saveInProgress = false
          self.saveAndViewInProgress = false

          if (this.mode === this.$constants.FORM_MODE.CREATE) {
            sharedEvents.emit('newAccountCreated')
          }

          self.$router.currentRoute.params.id = response.id

          return response.id
        })
        .catch(error => {
          this.saveInProgress = false
          this.saveAndViewInProgress = false

          this.$form.makeToastError(error.message)

          return Promise.resolve(false)
        })
    },
    cancel () {
      this.$router.back()
    },
    updateField (field, value) {
      let self = this
      let params = {}
      params['id'] = this.id
      params[field] = value

      //update local data values - it can be used in Edit action
      self.data[field] = value

      console.log('updateField', self.data)
      //if control in View mode then update database
      if (this.mode === this.$constants.FORMCONTROLMODE.VIEW)
        this.$api
          .put(`accounts/${this.data.id}`, params)
          .then(response => {
            self.$form.makeToastInfo(`${field}: ${response.message}`)

            self.controls[field].changed = false

            this.initialize()
          })
          .catch(response => {
            console.log(response)

            self.$form.makeToastError(`${field}: ${response.message}`)
          })
    },
    updateSelect (id, value) {
      this.updateField(id, value)
    },
    onVisibleGlobalChange (id, value) {
      this.data.visible_global = value

      this.updateField('visible_global', value)
    },

    fetchAccount: async function (id) {
      let self = this

      this.isLoading = true

      return this.$api
        .get(`/accounts/${id}`)
        .then(response => {
          console.log('response:', JSON.stringify(response))
          this.isLoading = false

          if (self.$_.isEmpty(response)) return

          self.data.id = self.id

          self.data.category = {
            id: response['Category'],
            label: response['Category']
          }
          self.data.type = {
            id: response['Type'],
            label: response['Type']
          }

          self.data.account_name = response['Account Name']
          self.data.website = response['Website']
          self.data.phone = response['Phone']
          self.data.fax = response['Fax']
          self.data.email = response['Email']
          /*
           *
           *  self.data.email_optout = response['email_optout'];
           */

          self.data.street = response['Street']
          self.data.country = {
            id: response['Country'],
            label: response['Country']
          }
          self.data.state = {
            id: response['State'],
            label: response['State']
          }
          self.data.city = {
            id: response['City'],
            label: response['City']
          }
          self.data.postal_code = response['Postal Code']
          self.data.pobox = response['PO Box']

          self.data.latitude = response['Latitude']
          self.data.longitude = response['Longitude']

          //shipping
          self.data.ship_street = response['Ship Street']
          self.data.ship_country = {
            id: response['Ship Country'],
            label: response['Ship Country']
          }
          self.data.ship_state = {
            id: response['Ship State'],
            label: response['Ship State']
          }
          self.data.ship_city = {
            id: response['Ship City'],
            label: response['Ship City']
          }
          self.data.ship_postal_code = response['Ship Postal Code']
          self.data.ship_pobox = response['Ship PO Box']

          //warehouse

          try {
            self.data.current_dist_contacts = JSON.parse(
              response['Current Dist Contacts']
            )
          } catch (e) {
            self.data.current_dist_contacts = []
          }

          self.data.ship_email = response['Shipping Email']
          self.data.ship_contact = response['Shipping Contact']
          self.data.bill_email = response['Billing Email']
          self.data.bill_contact = response['Billing Contact']

          self.data.tax_wh_number = response['Tax Warehouse Number']
          self.data.tax_wh_name = response['Tax Warehouse Name']
          self.data.fein = response['FEIN']
          self.data.auth_wh_keeper_number =
            response['Authorized WH Keeper Number']

          self.data.visible_global = response['Global'] === 'Yes' ? true : false
          //other
          self.data.description = response['Description']
          self.data.created = response['Created']
          self.data.modified = response['Modified']

          self.data.last_activity_id = response['last_activity_id']
          self.data.last_activity_date = response['last_activity_date']
          self.data.last_activity_subject = response['last_activity_subject']

          console.log('sedlf.data:', self.data)
        })
        .catch(error => {
          this.isLoading = false

          console.log(error)
        })
    }
  },
  watch: {
    'data.country': function (newVal) {
      if (newVal.id) {
        this.controls.state.options = this.$helpers.getDistinctArray(
          this.districts.filter(s => s.country === newVal.id),
          'state',
          'state',
          'id',
          'label'
        )

        this.isStateTaggable = newVal.label !== 'United States'
      }
    },
    'data.state': function (newVal) {
      if (newVal.id) {
        this.controls.city.options = this.$helpers.getDistinctArray(
          this.districts.filter(s => s.state === newVal.id),
          'city',
          'city',
          'id',
          'label'
        )
      }
    },

    'data.ship_country': function (newVal) {
      if (newVal.id) {
        this.controls.ship_state.options = this.$helpers.getDistinctArray(
          this.districts.filter(s => s.country === newVal.id),
          'state',
          'state',
          'id',
          'label'
        )
      }
    },
    'data.ship_state': function (newVal) {
      if (newVal.id) {
        this.controls.ship_city.options = this.$helpers.getDistinctArray(
          this.districts.filter(s => s.state === newVal.id),
          'city',
          'city',
          'id',
          'label'
        )
      }
    }
  }
}
</script>

<style>
/*contact preview modal resize */
.modal-lg {
  max-width: 70vw !important;
  max-height: 70vh !important;
}
</style>
